/* FOOTER styles */

footer {
    /* border: 1px dashed grey; */
    border-top: 0;
    height: 40px;
}

.control {
    margin-top: -12px;
}