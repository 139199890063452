html{ overflow: scroll}

@font-face { 
	font-family: Lato; 
	src: url('./assets/fonts/Lato/Lato-Regular.ttf'); 
  } 

  @font-face { 
	font-family: Helvetica; 
	src: url('./assets/fonts/Helvetica/HelveticaLTStd-Roman.otf'); 
  } 

  @font-face { 
	font-family: Poppins; 
	src: url('./assets/fonts/Poppins/Poppins-Regular.ttf'); 
  } 

  body {
    font: 16px/24px 'Lato', Helvetica, Arial, sans-serif;
    background: #fff;
    margin: 0;
    padding: 0;
    color: #666;
  }