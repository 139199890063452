/* HOME styles */

main {
    /* border: 1px dashed grey; */
    height: 450px;
}

.homepage{
/* padding-left: 12px !important; */
}

.square {

    height: 450px;
    width: 450px !important;
    /* border: 1px dashed red */
}

#slideshowHome{
    background-color: rgba(128, 128, 128, 0.103);
    height: 450px;
    width: 450px !important;
    margin-left: -12px;
}

#galleryHome {
    margin-left: 12px;
}

#contentMenuHome {
    margin-left: 10px;
    /* background-color: rgba(255, 0, 0, 0.1) */
}

#contentMenu {
    margin-left: 10px;
    background-color: white !important;
}

.squareOverlay {
 background-image: url("./../../images/overlays/squareOverlay.jpg");
 height: 450px;
 width: 450px;
 opacity: 0.1;
 /* margin-left: -12px; */

 /* background-image: 'url(${overlay})'; */
 /* background-size: cover;
 background-position: center; */

}